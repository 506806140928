<template>
  <div class="communications-sender-setup">
    <h3 class="mb-6 text-lg md:text-xl">Communications sender</h3>
    <div class="mb-4 sm:mb-8 md:mb-12">
      <p class="font-light text-sm md:text-base">This is the sender name that will appear when your customers receive payment request related emails from you.</p>
    </div>
      <vs-card class="no-padding-card no-shadow">
        <vs-row class="border rounded-lg border-slate-100 overflow-hidden">
        <vs-col vs-w="6" vs-lg="6" vs-sm="12" class="">
          <div class="py-4 px-6">
            <label class="label sm md:text-base font-light text-dark-blue">Email sender name</label>
            <vs-textarea
              class="small-textarea"
              counter="25"
              maxlength="25"
              name="emailSenderName"
              v-model="commsSender.emailSenderName"
              v-validate="'required|max:25'"
              data-vv-as="Email sender name"
              id="emailSenderName"
            ></vs-textarea>
            <span class="text-danger text-xs md:text-sm" v-show="errors.has('emailSenderName')">{{ errors.first("emailSenderName") }}</span>
          </div>
        </vs-col>

        <vs-col vs-w="6" vs-lg="6" vs-sm="12" class="gray">
          <div class="pt-5 pb-6 px-6 flex flex-row">
            <div class="pr-4">
              <feather-icon icon="UserIcon" svgClasses="w-4 h-4 text-gray" class="p-1 border rounded-full border-slate-400" />
            </div>
            <div class="w-full">
            <div>
              <p class="sm md:text-base text-pure-black">{{ commsSender.emailSenderName }}</p>
            </div>
            <div class="flex flex-row justify-between">
              <p class="text-xs md:text-sm font-medium">Secure Payment Request</p>
              <span class="text-xs md:text-sm ">11:45 am</span>
            </div>
              <p class="text-xs md:text-sm">Hi Jane Doe, Here’s your secure pay...</p>
            </div>
          </div>
        </vs-col>
        </vs-row>
      </vs-card>
    <div class="actions text-right">
      <vs-button class="mt-8 align-right" style="width: 200px; height: 60px" v-round @click="next">Next</vs-button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["step", "commsSender"],
  data() {
    return {
      step3: "",
    };
  },

  methods: {
    async next() {
      let valid = await this.$validator.validate();
      if (valid) {
        this.step3 = this.step + 1;
        this.$emit("increase", this.step3);
      }
    },
  },

  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
};
</script>

